import { elementClassAdd,getSelectSelectedOptionByElement } from "./features";

export class DataLayer {
	init() {
		this.initOneTimers();
		this.initListenersCart();// Product clicks
		this.initListenersProductDetail();
	}

	reInit() {
		this.initListenersProductDetail();
	}

	sendEvent(event, ecommerce) {
		window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
		window.dataLayer.push({
			event: event,
			ecommerce: ecommerce
		});
	}

	getProductObjectFromJSElement(element) {
		return this.createProductObject(
			element.dataset.id,
			element.dataset.name,
			element.dataset.price,
			element.dataset.brand,
			element.dataset.category1,
			element.dataset.category2,
			element.dataset.category3
		);
	}

	getProductObjectFromJSONElement(element) {
		return this.createProductObject(
			element.id,
			element.name,
			element.price,
			element.brand,
			element.category1,
			element.category2,
			element.category3
		);
	}

	createProductObject(id, name, price, brand, category1, category2, category3) {
		return {
			'item_name': name,
			'item_id': id,
			'price': price,
			'item_brand': brand,
			'item_category': category1,
			'item_category2': category2,
			'item_category3': category3,
			'item_category4': '',
			'item_category5': '',
			'item_variant': '',
			'quantity': 1
		}
	}

	initOneTimers() {
		let _this = this;

		// Product clicks
		[...document.querySelectorAll(".js-product-clickable")].forEach((elm) =>
			elm.addEventListener("click", function (e) {
				_this.sendEvent("eec.impressionClick", {
					click: {
						actionField: {
							list: window.Danfil.slug,
						},
						products: [_this.getProductObjectFromJSElement(elm)],
					},
				});

				_this.sendEvent("select_item", {
					items: [_this.getProductObjectFromJSElement(elm)],
				});
			})
		);

		// Megamenu clicks
		[...document.querySelectorAll(".js-megamenu a")].forEach((elm) =>
			elm.addEventListener("click", function (e) {
				window.dataLayer.push({
					megamenuItem:
					elm.parentElement.parentElement.querySelector(
						":first-child"
					).textContent,
					megamenuValue: elm.textContent,
					event: "megamenuClick",
				});
			})
		);

		// Custom jewellery form clicks
		[...document.querySelectorAll('.js-custom-jewellery-btn')].forEach(elm => elm.addEventListener('click', function (e) {
			window.dataLayer.push({
				'event': 'custom_jewellery',
				'custom_jewellery_budget': document.querySelector('.js-custom-jewellery-budget').value
			})
		}));

		// Add to favorites clicks
		[...document.querySelectorAll(".js-add-to-favorites")].forEach((elm) =>
			elm.addEventListener("click", function (e) {
				window.dataLayer.push({
					event: "addToFavorites",
					productAdded: elm.getAttribute("data-item-id"),
				});

				// Load different object on the product detail
				if (
					window.Danfil.pageType == "productDetail" ||
					window.Danfil.pageType == "configDetail"
				) {
					_this.sendEvent("add_to_wishlist", {
						items: [
							_this.getProductObjectFromJSONElement(
								productObject
							),
						],
					});
				} else {
					let product = elm.closest(".products__item");
					if (product === null && window.page) {
						console.error("Product object not found!");
					} else {
						_this.sendEvent("add_to_wishlist", {
							items: [
								_this.getProductObjectFromJSElement(product),
							],
						});
					}
				}
			})
		);

		// Login clicks
		[...document.querySelectorAll('.js-login-btn')].forEach(elm => elm.addEventListener('click', function (e) {
			window.dataLayer.push({
				'event': 'login',
				'method': elm.getAttribute('data-method')
			})
		}));

		// Sign up clicks
		[...document.querySelectorAll('.js-sign-up-btn')].forEach(elm => elm.addEventListener('click', function (e) {
			window.dataLayer.push({
				'event': 'sign_up',
				'method': elm.getAttribute('data-method')
			})
		}));

		// Share buttons clicks
		[...document.querySelectorAll('.js-share-btn')].forEach(elm => elm.addEventListener('click', function (e) {
			window.dataLayer.push({
				'event': 'share',
				'method': elm.getAttribute('data-method')
			})
		}));

		// Stone size change
		[...document.querySelectorAll(".js-change-stone-size")].forEach((elm) =>
			elm.addEventListener("change", function (e) {
				let selectedOption = getSelectSelectedOptionByElement(elm);
				window.dataLayer.push({
					event: "changeStoneSize",
					stoneSize: selectedOption
						? selectedOption.textContent
						: null,
				});
			})
		);

		// Price request
		[...document.querySelectorAll(".js-price-request")].forEach((elm) =>
			elm.addEventListener("click", function (e) {
				window.dataLayer.push({
					event: "priceRequest"
				});
			})
		);
	}

	initListenersCart() {
		let _this = this;

		// Delete element from cart
		[...document.querySelectorAll(".js-cart-delete")].forEach((elm) =>
			elm.addEventListener("click", function (e) {
				let $input = elm.parentElement.parentElement.querySelector(
					".js-cart-product-count"
				);

			if ($input !== null) {
				let product = _this.getProductObjectFromJSElement($input);
				product.quantity = $input.value;

					_this.sendEvent("remove_from_cart", {
						items: [product],
					});
				}
			})
		);

		// Cart product change
		[...document.querySelectorAll(".js-cart-product-count")].forEach(
			(elm) =>
				elm.addEventListener("change", function (e) {
					let previousValue = parseInt(
						elm.getAttribute("defaultvalue")
					);
					let newValue = parseInt(elm.value);
					let changeValue = newValue - previousValue;

					let product = _this.getProductObjectFromJSElement(elm);

					product.quantity = Math.abs(changeValue);


			if (changeValue > 0) {
				_this.sendEvent(
					'add_to_cart',
					{
						'items': [product]
					}
				);
			} else if (changeValue < 0) {
				_this.sendEvent(
					'remove_from_cart',
					{
						'items': [product]
					}
				);
			}
		}));
	}

	initListenersProductDetail() {
		let _this = this;

		[...document.querySelectorAll('.js-detail-buy:not(.-js-initiated)')].forEach(elm => {
			// add elm is initiated with the datalayer
			elementClassAdd(elm, '-js-initiated');

			// add click listener
			elm.addEventListener('click', function (e) {
				// detect size (in case it is valid at this product detail page)
				let sizeOpt = '0';
				let $sizeSelectedElement = document.querySelector('.product-detail__size select');
				if ($sizeSelectedElement !== null) {
					let sizeSelectedOption = getSelectSelectedOptionByElement($sizeSelectedElement);
					sizeOpt = sizeSelectedOption ? sizeSelectedOption.value : '0';
				}

				// continue only if:
				//  - product has NOT sizes
				//  - product has sizes and user has SELECTED some
				if ($sizeSelectedElement === null || sizeOpt !== "0") {
					let $chain = document.querySelector(
						".product-detail__chain input"
					);

					_this.sendEvent(
						'add_to_cart',
						{
							// productObject is set in template; it is not defined in any JS file
							'items': [_this.getProductObjectFromJSONElement(productObject)]
						}
					);

					if (typeof chainObject !== 'undefined' && $chain !== undefined && $chain.checked === true) {
						_this.sendEvent(
							'add_to_cart',
							{
								// chainObject is set in template; it is not defined in any JS file
								'items': [_this.getProductObjectFromJSONElement(chainObject)]
							}
						);
					}
				}
			})
		});

		// Add to favorites clicks
		[...document.querySelectorAll('.js-add-to-favorites')].forEach(elm => elm.addEventListener('click', function (e) {

			// Load different object on the product detail
			if (window.Danfil.pageType == 'productDetail' || window.Danfil.pageType == 'configDetail') {
				_this.sendEvent(
					'add_to_wishlist',
					{
						// productObject is set in template; it is not defined in any JS file
						'items': [_this.getProductObjectFromJSONElement(productObject)]
					});

			} else {
				let product = elm.closest('.products__item');
				if (product === null && window.page) {
					console.error('Product object not found!');
				} else {
					_this.sendEvent(
						'add_to_wishlist',
						{
							'items': [_this.getProductObjectFromJSElement(product)]
						});
				}
			}
		}));

		// Stone size change
		[...document.querySelectorAll('.js-change-stone-size:not(.-js-initiated)')].forEach(elm => {
			// add elm is initiated with the datalayer
			elementClassAdd(elm, '-js-initiated');

			// add click listener
			elm.addEventListener('change', function (e) {
				let selectedOption = getSelectSelectedOptionByElement(elm);
				window.dataLayer.push({
					'event': 'changeStoneSize',
					'stoneSize': selectedOption ? selectedOption.textContent : null
				})
			})
		});

		// price request
		[...document.querySelectorAll('.js-price-request:not(.-js-initiated)')].forEach(elm => {
			// add elm is initiated with the datalayer
			elementClassAdd(elm, '-js-initiated');

			// add click listener
			elm.addEventListener('click', function (e) {
				window.dataLayer.push({
					'event': 'priceRequest'
				})
			})
		});

	}
}